.error {
    display: block;
    color: #ba3232;
}

input.error-input {
    border-color: #ba3232;
}


/************01*04*2023************/

.back-arrow-head {
	display:flex;
	align-items:center;
	span {
		width: 36px;
		height: 36px;
		background:$background-black;
		border: 1px solid #111316;
		border-radius: 8px;
		display:flex;
		align-items:center;
		justify-content:center;
		margin-right:24px;
		svg {
			path {
				stroke: white;
			}
		}
	}
}
.light-theme {
	.back-arrow-head {
		display:flex;
		align-items:center;
		span {
			background: linear-gradient(180deg, #E8E8E8 0%, #E4E4E4 100%);
			border-color: #E3E3E3;
			svg {
				path {
					stroke:$background-grey-6;
				}
			}
		}
	}
	.arrow-heading {
		h3 {
			color:$background-black;
		}
	}
}

.arrow-heading {
	display:flex;
	align-items:center;
	margin-bottom:20px;
	h3 {
		margin:0 23px;
		color:$white;
	}
}
.track-img {
	margin-left: 12px;
	margin-right: -8px;
	img {
		width: 40px;
		height: 40px;
		border-radius: 6px;
		object-fit: cover;
	}
}

.select-box {
	position:relative;
	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}
	svg {
		position: absolute;
		right: 9px;
		top: 10px;
		pointer-events: none;
	}
}


ul.tab-sec {
	background:$background-grey-5;
	box-shadow: 0px 1px 0px #242629;
	border-radius: 8px;
	padding:2px;
	display:flex;
	align-items:center;
	margin-bottom:20px;
	
	li {
		position:relative;
		margin-right: 2px;
		span {
			background:transparent;
			border: 1px solid transparent;
			border-radius: 8px;
			padding:6px 30px;
			font-weight: 400;
			font-size:$fs-14;
			line-height: 18px;
			color:$light-grey;
			display:block;
			cursor: pointer;
			
			&:hover, &.active {
				background: rgba(235, 78, 39, 0.1);
				border: 1px solid $orange;
				color:$white;
			}
		}
		&.divide {
			&:after {
				border: 1px solid #111316;
				box-shadow: 1px 0px 0px #26282B;
				content:"";
				position:absolute;
				height: 20px;
				right: 2px;
				top: 6px;
			}
		}
	}
}


.table-content {
	background: $background-grey-6;
	border-radius: 6px;
	
	table {
		width:100%;
		border-collapse: collapse;
		tr {
			th, td {
				padding:16px;
				font-weight:$fw-600;
				font-size:$fs-12;
				line-height: 15px;
				color:#a3a3a3;
				text-align:left;
			}
			th {
				border-bottom: 1px solid #1D1F22;
			}
			td {
				color:$white;
			}
		}
		
	}
}

.light-theme {
    .form-control {
      background: #efefef;
      border: 1px solid #a3a3a3;
      color: #282a2d!important;
  }
  }