.full-section {
	width: calc(100% - 263px);
	
	.top-heading {
		.btn-sec {
			display: flex;
			button {
				min-width: 180px;
				margin-left: 10px;
			}
		}
	}
	.content-sec {
		.play-list-sec {
			margin-top:0;
			.inner-block {
				.play-list {
					.block {
						.play-track-content {
							& > div {
								&.divider {
									padding-right: 0;
									padding-left: 10px;
									&:after {
										right: auto;
										left: 0;
									}
								}
								.text-5 {
									margin:0 40px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.light-theme {
	.full-section {
		.content-sec {
			.play-list-sec {
				.inner-block {
					.play-list {
						.block {
							.play-track-content {
							  &	> div {
								span {
									svg {
										path {
											stroke: #282a2d;
										}
									}
								}
							  }
							}
						}
					}
				}
			}
		}
	}
}