.sign-in {
	display: flex;
	justify-content: center;
	height: 100vh;
	background:$background-linear;
	
	.sign-in-left, .sign-in-right {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		box-sizing: border-box;
	}
	.sign-in-left {
		align-items: flex-start;
		.top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 40px 40px 20px;
			box-sizing:border-box;
		}
		.form-sec {
			max-width: 330px;
			width: 100%;
			
			h4 {
				margin-bottom: 10px;
				color:$light-grey;
			}
			h1 {
				margin-bottom: 30px;
				color:$white;
			}
			.form-group {
				margin-bottom:16px;
				.text-3 {
					color:$white;
				}
			}
			.text-5 {
				color:$grey;
			}
			label.text-5 {
				color:$white;
				margin-bottom:6px;
				display:block;
			}
			.input-pass {
				position: relative;
				margin-bottom: 6px;
				
				svg {
					position: absolute;
					right: 13px;
					top: 13px;
				}
			}
			
			.custominput {
				margin-bottom:30px;
				.text-2 {
					color:$white;
				}
			}
			.fgp {
				margin-top:14px;
				text-align: center;
				display: block;
				text-decoration:none;
			}
		}
	}
	.sign-in-right {
		position:relative;
		overflow:hidden;
		&:after {
			background:url(../images/dark-bg.png) no-repeat;
			mix-blend-mode: overlay;
			opacity: 0.4;
			content:"";
			width: 100%;
			height: 100%;
			position: absolute;
		}
		.inner-bg {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			
			&:after {
				content: "";
				position: absolute;
				width: 473.26px;
				height: 255.33px;
				left: -71px;
				top: 186.27px;
				background: rgba(219, 137, 40, 0.72);
				filter: blur(200px);
				transform: rotate(-24.9deg);
			}
			&:before {
				content: "";
				position: absolute;
				width: 482.05px;
				height: 510.77px;
				right:-71px;
				top: 647.95px;
				background: rgba(235, 78, 39, 0.25);
				filter: blur(125px);
				transform: rotate(-31.23deg);
			}
		}
		.content {
			max-width:500px;
			position: relative;
			z-index: 1;
		
			.custom-font {
				font-weight: 600;
				font-size: 64px;
				line-height: 120%;
				margin-bottom: 20px;
				color:$white;
				
			}
			h2 {
				max-width: 365px;
				color:$light-grey;
			}
		}
	}
}





.light-theme {
	.sign-in {
		background:$white;
		
		
		.sign-in-left {
			.top {
				.close {
					svg path {
						stroke: #080A0D;
					}
				}
			}
			.form-sec {
				h4 {
					color:$grey;
				}
				h1 {
					color:$background-black;
				}
				.form-group {
					.form-control {
						background:$background-grey;
						border-color:$background-grey;
					}
					.text-3 {
						color:#A3A3A3;
					}
				}
				label.text-5 {
					color:#4E4E4E;
				}
				
				.text-5 {
					color:$grey;
				}
				.input-pass {
					svg {
						path {
							stroke:#282A2D;
						}
					}
				}
				.custominput {
					.text-2 {
						color:$background-black;
					}
				}
			}
		}
		.sign-in-right {
			background: linear-gradient(145.22deg, #FFFFFF 0%, #FFECE1 0.01%, #FFFFFF 100%);
			backdrop-filter: blur(20px);
			&:after {
				display:none;
			}
			.inner-bg {
				&:after, &:before {
					display:none;
				}
			}
			.content {
				max-width:500px;
			
				.custom-font {
					font-weight: 600;
					font-size: 64px;
					line-height: 120%;
					margin-bottom: 20px;
					color:$background-black;
					
				}
				h2 {
					max-width: 365px;
					color:#535353;
				}
			}
		}
	
	}
}
.error {
    display: block;
    color: #ba3232 !important;
}
input.error-input {
    border-color: #ba3232;
}
