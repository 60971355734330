@media (min-width:1440px) {
	.middle-section {
		.content-sec {
			.latest-spotlights {
				.latest-spot-block {
					.box:nth-child(5n) {
						// margin-right: 0;
					}
				}
			}
		}
	}
}

@media (max-width:1100px) {
	.sign-in {
		.sign-in-right {
			.content {
				max-width: 100%;
				padding: 0 20px;
				
				.custom-font {
					font-size: 54px;
					line-height: 110%;
				}
			}
		}
	}
}

@media (max-width:991px) {
	.sign-in {
		.sign-in-right {
			.content {
				.custom-font {
					font-size:44px;
					line-height: 100%;
				}
			}
		}
	}
}

@media (max-width:767px) {
	.sign-in {
		flex-wrap: wrap;
		position:relative;
		overflow:hidden;
		.sign-in-left, .sign-in-right {
			width: 100%;
		}
		.sign-in-right {
			display:none;
		}
		&:before {
			content: "";
			position: absolute;
			width: 154.31px;
			height: 144.7px;
			left: -33px;
			top: -17.02px;
			background: rgba(219, 137, 40, 0.72);
			filter: blur(200px);
			transform: rotate(-24.9deg);
		}
		&:after {
			content:"";
			position: absolute;
			width: 172.57px;
			height: 182.86px;
			right:-33px;
			bottom:-17px;
			background: rgba(235, 78, 39, 0.38);
			filter: blur(125px);
			transform: rotate(-31.23deg);
		}
	}
}

/******************************************************responsive *************************************************/

@media(max-width:767px) {
	.for-desktop{
		display: none;
	}
	.for-mobile{
		display: block;
	}
	.right-trending {
		border-left: none;
		margin-bottom: 32px;
	}
	
	.right-trending .track-list {
		padding: 20px 20px 10px;
	}
	.right-trending .track-list ul {
		display: flex;
		overflow: auto;
		padding-bottom: 10px;
	}	
	.right-trending .track-list ul li {
		margin-right: 8px;
		min-width: 260px;
		margin-bottom: 0;
	}
	.right-trending .top:after {
		display:none;
	}
	.right-trending .top {
		padding:25px 20px 0;
	}
	.left-nav {
		max-width: 0;
		border-left:none;
	}
	.left-nav.active {
		max-width: 262px;
		position: fixed;
		transition: all .5s;
	}
	.middle-section {
		padding-left: 0;
	}
	.middle-section {
		width: 100%;
	}
	.middle-section.active {
		width: 100%;
	}
	.middle-section .top-header {
		padding: 24px;
	}
	.middle-section .top-header .profile-name span {
		display: none;
	}
	.middle-section .top-header .profile-name {
		height: 28px;
		padding: 0;
		width: 28px;
	}
	.middle-section .top-header .profile-name img {
		margin-right: 0;
	}
	.middle-section .top-header .search .form-control {
		height: 32px;
	}
	.middle-section .top-header .search span#Search {
		top: 7px;
	}
	.middle-section .content-sec {
		padding: 0 24px 24px;
	}
	.middle-section .content-sec .play-list-sec .inner-block .top-list ul {
		flex-wrap: inherit;
		white-space: nowrap;
		overflow: auto;
		padding-bottom: 5px;
	}
	.middle-section .content-sec .slider-sec .box {
		margin-right: 16px;
	}
	.middle-section .content-sec .top-heading h2 {
		text-transform: capitalize;
	}
	.middle-section .content-sec .slider-sec .slick-slider .slick-arrow {
		display: none !important;
	}
	.middle-section .content-sec .slider-sec .slick-slider {
		margin-bottom: 0;
	}
	.right-trending{
		max-width: 100%;
	}	

	.middle-section .content-sec .play-list-sec .inner-block .play-list .block .play-track-content {
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
	}	
	.middle-section .content-sec .play-list-sec .inner-block .play-list .block .play-track-content > div {
		margin: 10px 0;
		width:100%;
	}
	.middle-section .content-sec .play-list-sec .inner-block .play-list .block .play-track-content > div.divider::after {
	display:none;
	}
	.middle-section .content-sec .play-list-sec .inner-block .play-list .block .play-track-content > div.divider {
		padding-right: 0;
		width:auto;
	}
	.middle-section .content-sec .play-list-sec .inner-block .play-list .block .play-track-content > div:last-child {
		width: auto;
	}
	.middle-section .content-sec .play-list-sec .inner-block .play-list .block.active::after {
		top: 145px;
	}
	ul.tab-sec {
		overflow: auto;
		padding-bottom: 10px;
		white-space: nowrap;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .form-content>div {
		flex-wrap: wrap;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .form-content .form-group {
		max-width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .save-btn {
		max-width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .change-password .form-content .form-group {
		max-width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .change-password .primary-btn {
		max-width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .subscription-sec .subscription-block {
		flex-wrap: wrap;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .subscription-sec .subscription-block .subscription-box {
		margin: 0 20px 50px;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .left {
		margin: 0 0 20px;
		max-width: 100%;
		width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .right {
		width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block {
		flex-wrap: wrap;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .left .ticket-query {
		overflow-y: hidden;
		overflow-x: scroll;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .right .body-content .d-flex.justify-content-between {
		flex-wrap: wrap;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .right .body-content .ticket-number {
		margin: 0 0 5px;
		width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .left .form-content .form-group, .account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .left .primary-btn {
		max-width: 100%;
	}
	.middle-section .content-sec .pagingnation-sec ul li a {
		font-size: 14px;
		height: 30px;
		width: 30px;
	}
	.middle-section .content-sec .latest-spotlights .latest-spot-block .box {
		margin-left: 5px;
		margin-right: 5px;
	}
	.left-nav {
		.top-logo{
			.close-nav{
				display: block;
				position: absolute;
				right: -10px;
				top: 20px;
			}
		}
	}
	.account-info-sec .content-sec .account-block .account-inner-block .subscription-sec .payment-block {
		display: block;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .subscription-sec .payment-block .right {
		max-width: 100%;
		margin: 20px 0 0;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .subscription-sec .payment-block .left {
		max-width: 100%;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .right .body-content .d-flex.justify-content-between {
		flex-wrap: wrap;
		border-bottom: 1px solid #282828;
		padding-bottom: 20px;
		margin-bottom: 15px;
	}
	.table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		min-height: .01%;
		overflow-x: auto;
	}
	.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .right {
		max-width: 100%;
	}
	.chat-boat {
		word-wrap: break-word;
	}
}