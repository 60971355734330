.account-info-sec {
	width: calc(100% - 263px);
	.content-sec {
		max-width:836px;
		
		.account-block {
			
			.account-inner-block {
				background:$background-grey-5;
				border-radius: 8px;
				padding:32px 24px;
				color:$white;
				.title-heading {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom:12px;
					.log-link {
						display: flex;
						align-items: center;
						text-decoration: underline;
						svg {
							margin-right:11px;
						}
					}
				}
				.edit-photo {
					display:flex;
					align-items:center;
					.photo {
						position:relative;
						overflow:hidden;
						width:60px;
						height:60px;
						margin-right:14px;
						img {
							width: 60px;
							height: 60px;
							border-radius: 50%;
							object-fit: cover;
						}
						&:after {
							content: "";
							background: rgba(0,0,0,0.5);
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 1;
							border-radius:50%;
						}
						svg {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50% , -50%);
							z-index:2;
						}
					}
					& > span {
						font-weight: 500;
						font-size: 14px;
						line-height: 18px;
						text-decoration:underline;
					}
				}
				.form-content {
					margin:24px 0;
					padding:24px 0;
					position:relative;
					&:before, &:after {
						border: 1px solid #222427;
						box-shadow: 0px 1px 0px $background-grey-4;
						content:"";
						width:100%;
						position:absolute;
						top:0;
					}
					&:after {
						bottom:0;
						top:auto;
					}
					& > div {
						display:flex;
						justify-content:space-between;
					}
					.form-group {
						margin-bottom:16px;
						max-width:calc(50% - 8px);
						width:100%;
						label {
							margin-bottom:6px;
							display:block;
						}
					}
				}
				.save-btn {
					max-width:calc(50% - 8px);
					width:100%;
				}
				.change-password {
					.input-pass {
						position: relative;
						margin-bottom: 6px;
						svg {
							position: absolute;
							right: 13px;
							top: 13px;
						}
					}
				
				.form-content {
					padding:0 0 24px;
					margin:0 0 24px;
					&:before {
						display:none;
					}
					& > div {
						display:block;
					}
					.form-group {
						margin-bottom:16px;
						max-width:50%;
						label {
							color: #fff;
							margin-bottom: 6px;
							display: block;
						}
						span.text-5 {
							color:#828282;
						}
					}
				}
				.primary-btn {
					max-width: 50%;
				}
				}
				
				.ticket-sec {
					.inner-ticket-block {
						display:flex;
						
						.left {
							max-width:calc(100% - 316px);
							margin-right:16px;
							.ticket-query {
								background:$background-grey-4;
								box-shadow: 0px 1px 0px #242629;
								border-radius: 6px;
								padding:16px;
								display:flex;
								align-items:center;
								position: relative;
								margin-bottom: 48px;
								&:after {
									border: 1px solid #222427;
									box-shadow: 0px 1px 0px $background-grey-4;
									content: "";
									position: absolute;
									width: 100%;
									left: 0;
									bottom: -24px;
								}
								.box {
									position:relative;
									padding:0 9px 0 0;
									margin:0 16px 0 0;
									min-width: 135px;
									&:before{
										border: 1px solid #111316;
										box-shadow: 1px 0px 0px #26282B;
										content:"";
										position:absolute;
										height:52px;
										top:6px;
										right:0;
									}
									&:last-child {
										&:before {
											display:none;
										}
									}
									span {
										font-weight:$fw-600;
										font-size:$fs-20;
										line-height: 26px;
										color:$yellow;
										background:$background-black;
										border: 1px solid #030508;
										box-shadow: 0px 1px 0px #242629;
										display:flex;
										align-items:center;
										justify-content:center;
										margin-bottom:8px;
										border-radius:50%;
										height:36px;
										width:36px;
									}
								}
							}
							& > p {
								font-weight:$fw-400;
								font-size:$fs-14;
								line-height: 160%;
								color:#a3a3a3;
								margin:16px 0 24px;
							}
							.department {
								position:relative;
								padding:24px 0 0;
								&:before{
									border: 1px solid #222427;
									box-shadow: 0px 1px 0px $background-grey-4;
									content:"";
									position:absolute;
									width:100%;
									top:0;
									right:0;
								}
								h5 {
									margin-bottom:18px;
								}
								form {
									& > div {
										margin-bottom:18px;
									}
								}
							}
							.form-content {
								& > div {
									display:block;
								}
								.form-group {
									max-width:330px;
								}
							}
							.primary-btn {
								max-width:330px;
							}
						}
						
						.right {
							background: $background-grey-4;
							box-shadow: 0px 1px 0px #242629;
							border-radius: 6px;
							padding:24px 16px;
							max-width:300px;
							width:100%;
							.title-heading {
								position:relative;
								margin-bottom: 24px;
								padding-bottom: 24px;
								&:after {
									border: 1px solid #222427;
									box-shadow: 0px 1px 0px $background-grey-4;
									content:"";
									position:absolute;
									width:100%;
									bottom:0;
									left:0;
								}
							}
							.body-content {
								p {
									font-weight:$fw-400;
									font-size:$fs-14;
									line-height: 18px;
									color:#a3a3a3;
								}
							}
						}
					}
				}
				
				.subscription-sec {
					.top {
						text-align:center;
						h5 {
							color:$light-grey;
						}
						h1 {
							margin:20px 0 10px;
						}
						p {
							color:#A3A3A3;
							margin: 0 auto;
							max-width: 350px;
						}
						
					}
					.subscription-block {
						display:flex;
						justify-content:center;
						margin-top: 55px;
						.subscription-box {
							background:$background-grey-6;
							box-shadow: 0px 1px 0px #242629;
							border-radius: 6px;
							padding:10px;
							margin:0 15px;
							position:relative;
							
							&:before {
								content:"";
								position:absolute;
								background:$background-grey-6;
								width: 66px;
								height: 66px;
								top: -23px;
								z-index: 1;
								left: 50%;
								transform: translate(-50% , 0);
								border-radius: 50%;								
							}
							.inner-block {
								background:$background-grey-4;
								box-shadow: 0px 1px 0px #242629;
								border-radius: 4px;
								padding:16px;
								position: relative;
								z-index: 2;
								width:300px;
								margin-bottom:10px;
								min-height:478px;
								box-sizing: border-box;
								
								&:before {
									content:"";
									position:absolute;
									background:$background-black;
									border: 1px solid #030508;
									box-shadow: 0px 1px 0px #242629;
									width: 46px;
									height: 46px;
									top: -23px;
									z-index: 1;
									left: 50%;
									transform: translate(-50% , 0);
									border-radius: 50%;								
								}
								.icon {
									position:absolute;
									top: -10px;
									left: 50%;
									z-index: 2;
									transform: translate(-50% , 0);
								}
								h2 {
									text-align:center;
									margin-top:20px;
									position:relative;
									padding-bottom:12px;
									
									&:after {
										border: 1px solid #030508;
										box-shadow: 0px 1px 0px #242629;
										content:"";
										width: calc(100% + 30px);
										position: absolute;
										left: -16px;
										bottom: 0;
									}
								}
								h1 {
									margin:16px 0;
									text-align:center;
									
									span {
										font-weight: 400;
										font-size: 12px;
										line-height: 15px;
										color:#a3a3a3;
										margin-left: 2px;
									}
								}
								& > div.plan-text{
									background:$background-grey-5;
									border-radius: 10px;
									padding:6px;
									text-align:center;
									font-weight:$fw-400;
									font-size:$fs-12;
									line-height: 160%;
									color:#a3a3a3;
									span {
										color:$white;
									}
								}
								.feature-list {
									background:none;
								}
								ul {
									margin-top:16px;
									padding-top:24px;
									position:relative;
									
									&:after {
										border: 1px solid #030508;
										box-shadow: 0px 1px 0px #242629;
										content:"";
										width: calc(100% + 30px);
										position: absolute;
										left: -16px;
										top: 0;
									}
									
									li {
										font-weight:$fw-400;
										font-size:$fs-14;
										line-height: 20px;
										margin-bottom:12px;
										background: url(../images/checkbox.svg);
										padding: 0 0 0 30px;
										background-repeat: no-repeat;
										background-size: 20px;
										background-position: left center;
									}
								}
							}
						}
					}
					.payment-block {
						margin-top:32px;
						display:flex;
						justify-content: space-between;
						.left {
							max-width:330px;
						}
						.pay-sec {
							
								.form-check {
									margin-top:18px;
								}
							
						}
						.form-content {
							margin: 0 0 24px;
							padding: 25px 0 10px;	
							&:before {
								display:none;
							}
							& > div {
								display:block;
							}
							.form-group {
								max-width: 100%;
							}
							.two-column {
								display:flex;
								.form-group {
									max-width:calc(50% - 8px);
								}
							}
						}
						.custominput {
							margin-bottom: 25px;
							margin-top: 18px;
							span {
								a {
									color:$white;
									font-size:$fs-14;
									font-weight:$fw-500;
									text-decoration:underline;
								}
							}
						}
						.paypal-payment {
							background:$background-grey-4;
							box-shadow: 0px 1px 0px #242629;
							border-radius: 6px;
							padding:16px;
							margin-top: 25px;
							span {
								font-weight:$fw-400;
								font-size:$fs-14;
								line-height: 160%;
								color:$light-grey;
							}
						}
						.right {
							max-width:362px;
							width:100%;
							.box {
								background:$background-grey-4;
								box-shadow: 0px 1px 0px #242629;
								border-radius: 6px;
								padding:24px 16px;
								width:100%;
								margin-bottom:24px;
								box-sizing: border-box;
								.top {
									position:relative;
									display:flex;
									justify-content:space-between;
									padding-bottom:16px;
									margin-bottom:16px;
									&:after {
										border: 1px solid $background-dark-grey;
										box-shadow: 0px 1px 0px #242629;
										content:"";
										position: absolute;
										bottom: 0;
										width: 100%;
									}
								}
								.body-text {
									padding-bottom:16px;
									margin-bottom:16px;
									position:relative;
									&:after {
										border: 1px solid $background-dark-grey;
										box-shadow: 0px 1px 0px #242629;
										content:"";
										position: absolute;
										bottom: 0;
										width: 100%;
									}
									span {
										display:block;
										margin-bottom:10px;
										&.text-3 {
											color:$light-grey;
										}
									}
								}
								p.text-5 {
									color:$light-grey;
									a {
										font-size:$fs-14;
										font-weight:$fw-500;
										line-height:22px;
										color:$light-grey;
										text-decoration:underline;
									}
								}
								.form-content {
									margin:18px 0 0;
									padding:0;
									&:after,&:before {
										display:none;
									}
								}
								&.promo-apply {
									.secondary-btn {
										height:40px;
										font-size:$fs-12;
									}
								}
							}
						}
						&.mobile-payment {
							justify-content: center;
						}
						&.msg-popup {
							justify-content: center;
							margin: 0;
							padding: 98px;
							.right {
								max-width:330px;
								.box {
									padding:40px 30px;
									.body-text {
										margin:0;
										padding:0;
										text-align:center;
										&:after {
											display:none;
										}
										h1 {
											margin:20px 0 10px;
										}
										span.text-3 {
											color:#a3a3a3;
										}
									}
								}
								a.text-2 {
									text-decoration: underline;
									margin-top: 16px;
									display: block;
									text-align: center;
								}
							}
						}
					}
					.payment-text {
						max-width: 386px;
						margin-top: 100px;
						p {
							font-size:$fs-14;
							font-weight:$fw-500;
							line-height:22px;
							color:$light-grey;
							margin:0;
							span {
								color:$orange;
							}
						}
					}
				}
				.billing-history {
					.billing-years {
						margin: 24px 0 24px;
						padding-bottom: 24px;
						position:relative;
						display: flex;
						justify-content: space-between;
						align-items: center;
						
						&:after {
							border: 1px solid #222427;
							box-shadow: 0px 1px 0px #16181B;
							content:"";
							width: 100%;
							position: absolute;
							bottom: 0;
						}
					}
					ul.top-bages{
						display: flex;
						
						li {
							margin-right:8px;
							span {
								border: 1px solid $grey;
								border-radius: 8px;
								padding:4px 8px;
								display:flex;
								align-items:center;
								justify-content:center;
								color:#828282;
								&:hover {
									background:$orange;
									border-color:$orange;
									color:$white;
									font-weight:$fw-500;
								}
							}
							&.active {
								span {
									background:$orange;
									border-color:$orange;
									color:$white;
									font-weight:$fw-500;
								}
							}
							&.calendar {
								span {
									width: 32px;
									height: 32px;
									border-radius: 50%;
									padding: 0;
									background:$orange;
									border-color:$orange;
								}
							}
						}
					}
					.billing-button {
						display:flex;
						margin-top:24px;
						padding-top:24px;
						position:relative;
						&:after {
							border: 1px solid #222427;
							box-shadow: 0px 1px 0px #16181B;
							content:"";
							width: 100%;
							position: absolute;
							top: 0;
						}
						button {
							font-size:$fs-12;
							line-height:15px;
							height: 32px;
							max-width: 180px;
							margin-right:8px;
						}
					}
				}
				
				.faq-sec {
					.faq-block {
					margin-top:25px;
						.faq-box, .accordion-item {
							background:$background-grey-4;
							border-radius: 6px;
							margin-bottom:10px;
							.accordion-button {
								background: none !important;
								color: #fff;
								span {
									font-size: 14px;
									line-height: 18px;
									font-weight: 600;
								
								}
								&:focus {
									outline: none !important;
									box-shadow: none;
								}
							}
							p {
								margin: 0;
								color: #fff;
							}
							.top {
								display:flex;
								justify-content:space-between;
								align-items: center;
								padding:8px 8px 8px 16px;
								span {
									background:$background-dark-grey;
									border-radius: 8px;
									width: 36px;
									height: 36px;
									display: flex;
									align-items: center;
									justify-content: center;
									cursor:pointer;
								}
								h5 {
									max-width:calc(100% - 50px);
								}
							}
							.content {
								padding:8px 16px 16px;
								font-weight: 400;
								font-size: 14px;
								line-height: 160%;
								color:$light-grey;
								max-width: 80%;
								display:none;
							}
							&.active {
								.content {
									display:block;
								}
							}
						}
					}
				}
				
			}
			.change-plan-popup {
				background: $background-grey-5;
				border-radius: 8px;
				padding:30px;
				max-width:440px;
				box-sizing: border-box;
				width:100%;
				text-align:center;
				
				h2 {
					color:$white;
					text-align:left;
				}
				p {
					margin:16px 0 24px;
					color:$light-grey;
					text-align:left;
					span {
						color:$orange;
					}
				}
				button {
					margin-bottom:16px;
				}
				a {
					color:$white;
					text-decoration:underline;
					text-align:center;
				}
			}
			.payment-method {
				background:$background-grey-5;
				border-radius: 8px;
				padding:32px 24px;
				margin-top:30px;
				display: flex;
				align-items: center;
				p {
					margin:0 40px 0 0;
					padding-right: 40px;
					font-weight:$fw-400;
					font-size:$fs-14;
					line-height: 160%;
					color:#a3a3a3;
					position:relative;
					
					&:after {
						border: 1px solid #111316;
						box-shadow: 1px 0px 0px #26282B;
						content:"";
						position: absolute;
						height: 44px;
						right: 0;
						top: 0;
					}
				}
			}
		}
	}
}





.light-theme{
	[type=radio]:checked + label:before {
		border-color: #535353;
	}
	[type=radio]:checked + label {
		color: #535353;
	}
	[type=radio]:checked + label:after, [type=radio]:not(:checked) + label:after {
		content: "";
		background: #000;
	}
	ul.tab-sec {
		background: #fff;
		box-shadow: 0px 1px 0px #e4e4e4;
		 li {
			span {
				color: #a3a3a3;
				&.active {
					color: #080A0D;
				}
			}
			&.divide {
				&:after {
					border: 1px solid #f0f0f0;
					box-shadow: 1px 0px 0px #f8f8f8;
					content: "";
				}
			}
			
		}
	}
	.account-info-sec {
		.content-sec {
			.account-block {
				.account-inner-block {
					background: #fff;
					color: #080a0d;
					border: 1px solid #f0f0f0;
					.title-heading {
						.log-link {
							svg {
								path {
									stroke: #080A0D;
								}
							}
						}
					}
					.form-content {
						&:before, &:after {
							border: 1px solid #f0f0f0;
							box-shadow: 0px 1px 0px #f8f8f8;
							content: "";
						}
						.form-group {
							label {
								color: #4e4e4e;
							}
						}
						
					}
					.subscription-sec {
						.top h5 {
							color: #303030;
						}
						.subscription-block {
							.subscription-box {
								background: #efefef;
								box-shadow: 0px 1px 0px #f8f8f8;
								 &:before {
									content: "";
									background: #efefef;
								}
								  .inner-block {
									background: #fcfcfc;
									box-shadow: 0px 1px 0px #e6e6e6;
									&:before {
										content: "";
										background: #EB4E27;
										border: none;
										box-shadow: none;
									}
									& > div.plan-text {
										background: #EFEFEF;
										color: #828282;
										span {
											color:#080A0D;
										}
									}

									ul, h2{
										&:after {
											border: 1px solid #f0f0f0;
											box-shadow: 0px 1px 0px #e5e5e5;
											content: "";
										}
									}
								}
							}
						}
						.payment-block {
							.custominput {
								span.text-5 {
									color: #535353;
								}
							}
							.paypal-payment {
								background: #efefef;
									box-shadow: 0px 1px 0px #f8f8f8;
								span {
									color:#535353;
								}	
							}
							.right {
								.box {
									background: #efefef;
									box-shadow: 0px 1px 0px #f8f8f8;
									.top {
										&:after {
											border: 1px solid #f0f0f0;
											box-shadow: 0px 1px 0px #e5e5e5;
											content: "";
										}
									}
									.body-text {
										span {
											color: #535353;
										}
										&:after {
											border: 1px solid #f0f0f0;
											box-shadow: 0px 1px 0px #e5e5e5;
											content: "";
										}
									}
									p.text-5 {
										color: #a3a3a3;
										a {
											color: #a3a3a3;
										}
									}
								}
							}
						}
					}
					.billing-history {
						.billing-button:after {
							border: 1px solid #f0f0f0;
							box-shadow: 0px 1px 0px #f8f8f8;
						}
						ul.top-bages {
							li {
								span {
									border: 1px solid #a3a3a3;
									color: #a3a3a3;
								}
								&.active {
									span {
										border: 1px solid #EB4E27;
										color: #fff;
									}
								}
							}
						}
						.billing-years {
							&:after {
								border: 1px solid #f0f0f0;
								box-shadow: 0px 1px 0px #f8f8f8;
								content: "";
								
							}	
						}
					}

					.faq-sec {
						.faq-block {
							.faq-box, .accordion-item {
								background: #fbfbfb;
								border: 1px solid #f5f5f5;
								.accordion-button{
									color: #282A2D;
								}
								p {
									color:#535353;
								}
							}
						}
					}
					.ticket-sec {
						.inner-ticket-block {
							.left {
								p {
									color:#535353;
								}
								.ticket-query {
									background: #f9f9f9;
								box-shadow: 0px 1px 0px #f0f0f0;
								.box {
									span {
										color: #EB4E27;
										background: #fff;
										border: 1px solid #f0f0f0;
										box-shadow: 0px 1px 0px #f0f0f0;
									}
									h5 {
										color:#080A0D;
									}
									&:before {
										border: 1px solid #f0f0f0;
										box-shadow: 1px 0px 0px #f8f8f8;
									}
								}
								&:after {
									border: 1px solid #f0f0f0;
									box-shadow: 1px 0px 0px #f8f8f8;
								}
								}
								.department {
									&:before {
										border: 1px solid #f0f0f0;
										box-shadow: 1px 0px 0px #f8f8f8;
									}
								}
							}
							.right {
								background: #f9f9f9;
								box-shadow: 0px 1px 0px #f0f0f0; 
								.title-heading {
									&:after {
										border: 1px solid #f0f0f0;
										box-shadow: 1px 0px 0px #f8f8f8;
									}
								}
								.body-content {
									color:#535353;
								}
							}
						}
					}
			}
		}
	}
}
}