.full-section {
	.content-sec {
		.top-tab {
			margin-bottom:32px;
			display:flex;
			.tab-sec {
				margin:0;
			}
		}
		.top-heading {
			h3 {
				color:$white;
			}
		}
	}
}