.full-section {
	.content-sec {
		.top-tab {
			justify-content:space-between;
			.tab-sec {
				margin:0;
			}
			ul.top-bages{
				display: flex;
				li {
					margin-right:8px;
					span {
						border: 1px solid $grey;
						border-radius: 8px;
						padding:4px 8px;
						display:flex;
						align-items:center;
						justify-content:center;
						color:#828282;
						&:hover {
							background:$orange;
							border-color:$orange;
							color:$white;
							font-weight:$fw-500;
						}
					}
					&.active {
						span {
							background:$orange;
							border-color:$orange;
							color:$white;
							font-weight:$fw-500;
						}
					}
					&.calendar {
						span {
							width: 32px;
							height: 32px;
							border-radius: 50%;
							padding: 0;
							background:$orange;
							border-color:$orange;
						}
					}
				}
			}
		}
		.top-heading {
			h3 {
				color:$white;
			}
		}
	}
}