.editor {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .editor-section {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
  }
  
  .player-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
.artwork-container{
    display: none;
}
.rhap_progress-section{
  display:none;
}

.rhap_main-controls{
  margin-right: 5%;
}
.wave-bar{
  width: 30%;
}
.rhap_container {
  background: none;
  padding: 0;
  color: #FFF;
  box-shadow: none;
  line-height: normal;
}
.playlist {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 0 15px;
}
.playlist-container{
  display: none;
  position: absolute;
  width: 295px;
  bottom: 25px;
  z-index: 999;
  background: #222;
  padding: 20px;
  left: -26px;
}
.playlist-item {
  display: flex;
  justify-content: space-between;
}
.rhap_main-controls-button.rhap_skip-button {
  width: auto;
  height: auto;
}
.rhap_additional-controls {
  flex: initial;
}
.rhap_main-controls-button {
  margin: 0 10px;
}
.rhap_time {
  color: #A3A3A3;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  font-weight: 400;
  line-height: 15px;
}
.rhap_time.rhap_current-time {
  color: #fff;
}
.light-theme .rhap_time.rhap_current-time, .light-theme .rhap_time.rhap_current-time + div{
  color: #828282;
}
.rhap_time.rhap_current-time + div {
  margin: 0 3px;
}
.rhap_volume-bar {
  height: 2px;
  background: #080A0D;
}
.rhap_volume-filled {
  background-color: #EB4E27;
}
.rhap_volume-container {
  min-width: 120px;
}
.rhap_volume-indicator {
  background: #eb4e27;
  box-shadow: rgb(235 78 39 / 50%) 0 0 3px;
}
.rhap_volume-button {
  flex: 0 0 20px;
  font-size: inherit;
  width: 20px;
  height: 20px;
  color: #868686;
  margin-right: 12px;
}
.song-name {
  margin-right: 50px;
}
button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
  background: #16181B;
  box-shadow: 0px 1px 0px #242629;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wave-bar {
  margin-left: 11px;
}
.rhap_main-controls {
  position: relative;
  margin-right: 2.5%;
  padding-right: 2.5%;
}
.rhap_main-controls:after {
  content: "";
  border: 1px solid #111316;
  box-shadow: 1px 0px 0px #26282B;
  position: absolute;
  right: 0;
  height: 40px;
}
.play-bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  z-index: 99;
}
.play-bar-right-icon {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 30px;
  margin-left: 30px;
}
.play-bar-right-icon .playlist {
  width: auto;
  height: auto;
  margin: 0 10px;
}
.play-bar-right-icon > span {
  display: block;
  margin: 0 10px;
}
.play-bar-right-icon:before {
  content: "";
  border: 1px solid #111316;
  box-shadow: 1px 0px 0px #26282B;
  position: absolute;
  left: 0;
  height: 40px;
}
.play-bar-right-icon:after {
  content: "";
  border: 1px solid #111316;
  box-shadow: 1px 0px 0px #26282B;
  position: absolute;
  right: 0;
  height: 40px;
}


.light-theme {
  .rhap_main-controls {
    &:after {
      content: "";
      border: 1px solid #f0f0f0;
      box-shadow: 1px 0px 0px #fff;
     }
  }
  .play-bar-right-icon{
    &:before, &:after {
      content: "";
      border: 1px solid #f0f0f0;
      box-shadow: 1px 0px 0px #fff;
  }
  }
  .rhap_volume-bar {
     background: linear-gradient(180deg, #E8E8E8 0%, #E4E4E4 100%);
    box-shadow: 0px 1px 0px #FFFFFF;
}
button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
  box-shadow: 0px 1px 0px #dcdcdc;
  background: linear-gradient(180deg, #E3E3E3 0%, #F8F8F8 100%);
  svg path {
    fill:#EB4E27;
  }
}


}