.dj-section {
	position: relative;
		overflow: hidden;
		background: #121417 url(../images/dj-bg.svg) no-repeat;
		backdrop-filter: blur(20px);
		min-height:100vh;
		background-size: 100%;
	.top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 40px 40px 20px;
			box-sizing: border-box;
		}
	.dj-inner {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 224px);
		padding: 50px 0;

		
		.dj-block {
			background:$background-grey-5;
			border-radius: 8px;
			padding:30px;
			max-width:440px;
			margin:0 auto;
			box-sizing: border-box;
			h2 {
				margin-bottom:16px;
				color:$white;
			}
			.dj-content {
				background:$background-grey-4;
				border-radius: 6px;
				padding:10px;
				max-height: 168px;
				overflow: auto;
				box-sizing: border-box;
				p {
					color:$light-grey;
					font-weight:$fw-400;
					font-size:$fs-14;
					line-height: 160%;
					margin:0;
				}
				
			}
			.dj-form {
					margin:16px 0 0;
					span.text-4 {
						color:$light-grey;
						span {
							color:$orange;
						}
					}
					.form-group {
						margin-bottom:16px;
						.textarea-control {
							background:$background-grey-6;
							border-radius: 10px;
							padding: 14px;
							min-height: 96px;
							font-weight: 400;
							font-size: 14px;
							line-height: 18px;
							color: $grey;
							border:none;
}
					}
					
					.switch-sec {
						margin:24px 0;
						& > div {
							margin-bottom:16px;
							display: flex;
							align-items: center;
							.text-2 {
								color:$light-grey;
								margin-left:10px;
							}
						}
						.form-switch {
							position: relative;
							display: inline-block;
							width:32px;
							height:18px;
						}
							
						.form-switch input { 
							opacity: 0;
							width: 0;
							height: 0;
						}
						
						.form-check-label {
							position: absolute;
							cursor: pointer;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							-webkit-transition: .4s;
							transition: .4s;
							background: $background-grey-4;
							box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
						}
						
						.form-check-label:before {
							position: absolute;
							content: "";
							height:16px;
							width:16px;
							left:1px;
							bottom:1px;
							background:$background-grey-6;
							box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
							-webkit-transition: .4s;
							transition: .4s;
						}
						
						input:checked + .form-check-label {
							background-color: $background-grey-4;
						}
						
						input:focus + .form-check-label {
							box-shadow: 0 0 1px $background-grey-4;
						}
						
						input:checked + .form-check-label:before {
							-webkit-transform: translateX(15px);
							-ms-transform: translateX(15px);
							transform: translateX(15px);
						}
						input:checked + .form-check-label:before {
							background:$orange;
						}
						
						/* Rounded sliders */
						.form-check-label {
							border-radius:21px;
						}
						
						.form-check-label:before {
							border-radius:21px;
						}
						.error-text {
							font-weight:$fw-600;
							font-size:$fs-12;
							line-height: 15px;
							color: #FF3030;
						}
					}
				}
		}
	}
}