.genres-sec {
	.genres-block {
		display:flex;
		align-items: flex-start;
		margin-bottom: 30px;
		.img-block {
			position:relative;
			max-width: 149px;
			margin-right:30px;
			img {
				width:149px;
				height:149px;
				border-radius:6px;
				object-fit:cover;
			}
			h5 {
				background: rgba(255, 255, 255, 0.2);
				backdrop-filter: blur(10px);
				border-radius: 0px 0px 8px 8px;
				color: #fff;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 10px 15px;
				box-sizing: border-box;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				display: block;
				text-align: center;
			}
			
		}
		.genres-right-block {
			width: calc(100% - 179px);
			.tags {
				display: flex;
				flex-wrap: wrap;
				span {
					border: 1px solid $grey;
					border-radius: 8px;
					padding: 4px 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 400;
					font-size: 14px;
					line-height: 18px;
					color: $grey;
					margin-right: 10px;
					margin-bottom: 10px;
					cursor:pointer;
					&:hover, &.active {
						color:$orange;
						border-color:$orange;
					}
				}
			}
		}
	}
}