@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

$dark-grey: #717171;
$light-grey: #D7D7D7;
$grey:#828282;
$white: #fff;
$black: #000;
$orange:#EB4E27;
$yellow:#F4FD84;

$background-dark-grey:#0E1013;
$background-linear:linear-gradient(101.43deg, #181A1D 7%, #0A0C0F 89.6%);
$background-black:#080A0D;
$background-grey-4:#16181B;
$background-grey-5:#1C1E21;
$background-grey-6:#282A2D;
$background:#1E2023;

$background-light-grey:#F9F9F9;
$background-white-1:#FCFCFC;
$background-grey:#EFEFEF;

$hover:#652C1F;

$border-radius: 12px;

$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-20: 20px;
$fs-36: 32px;

$fw-700: 700;
$fw-600: 600;
$fw-500: 500;
$fw-400: 400;
$fw-300: 300;
$primary-font-family:'Space Grotesk', sans-serif;


body {
    padding: 0px;
    margin: 0px;
    font-family: $primary-font-family;
    font-size: $fs-14;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; /* Chrome, Safari */
    text-rendering: optimizeLegibility; /* Firefox */
    font-weight: $fw-400;
  }
  
  img {
    max-width: 100%;
  }
  
  h1 {
	font-size:$fs-36;
	line-height:41px;
	font-weight:$fw-600;
  }
  h2 {
	font-size:$fs-20;
	line-height:26px;
	font-weight:$fw-600;
  }
  h3 {
	font-size:$fs-16;
	line-height:20px;
	font-weight:$fw-600;
  }
  h4 {
	font-size:$fs-16;
	line-height:20px;
	font-weight:$fw-500;
  }
  h5 {
	font-size:$fs-14;
	line-height:18px;
	font-weight:$fw-600;
  }
  button {
	border: none;
	width: 100%;
}
  .primary-btn, .secondary-btn {
	background:$orange;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	font-weight:$fw-600;
	font-size:$fs-16;
	line-height: 20px;
	color:$white;
	height:52px;
  }
  .secondary-btn {
	border:1px solid $background-black;
	color:$background-black;
	background:$white;
  }
  
  .form-control {
	background:#303030;
	border: 1px solid #4E4E4E;
	border-radius: 10px;
	height: 40px;
	padding: 0 14px;
	width: 100%;
	color:$white !important;
	box-sizing: border-box;
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    word-break: break-word;
  }
  
  p {
    word-break: break-word;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: $primary-font-family;
  }
  
  button:focus {
    outline: none !important;
  }
  

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  a,a:hover {
    text-decoration: none;
  }

.text-1 {
	font-weight:$fw-400;
	font-size:$fs-16;
	line-height:20px;
	color:$light-grey;
}
.text-2 {
	font-weight:$fw-500;
	font-size:$fs-14;
	line-height:18px;
	color: #fff;
}
.text-3 {
	font-weight:$fw-400;
	font-size:$fs-14;
	line-height:18px;
	color:#717171;
}
.text-4 {
	font-weight:$fw-600;
	font-size:$fs-12;
	line-height:15px;
	color: #fff;
}
.text-5 {
	font-weight:$fw-400;
	font-size:$fs-12;
	line-height:15px;
	color:#fff;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 0 !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 22px;
  
  input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  input:checked ~ .checkmark {
	background-color: $orange;
	border-color:$orange;
   }
  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border:1px solid #dcdcdc;
	border-radius:5px;
	
	&:after {
		content: "";
		position: absolute;
		display: none;
		left: 7px;
		top: 4px;
		width: 4px;
		height: 8px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
  }	
	
	input:checked ~ .checkmark:after {
		display: block;
	}
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #A3A3A3;
}
[type="radio"]:checked + label {
	color:$white;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #4E4E4E;
    border-radius: 100%;
    background:none;
}
[type="radio"]:checked + label:before {
	border-color:$white;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width:8px;
    height:8px;
    background:$white;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.mt-0 {
	margin-top:0 !important;
}
.mt-5 {
	margin-top:32px !important;
}










