    .fpass{
    color:#0099cc;
    font-size:11px;
    cursor: pointer;
    padding:0 0px;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translateY(-50px)
}

@media(prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .5rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 2px solid #f5f5f5;
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.6
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 2px solid #f5f5f5;
    border-bottom-right-radius: calc(.5rem - 1px);
    border-bottom-left-radius: calc(.5rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

@media(min-width: 576px) {
    .modal-dialog {
        max-width:500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width: 992px) {
    .modal-lg,.modal-xl {
        max-width:800px
    }
}

@media(min-width: 1200px) {
    .modal-xl {
        max-width:1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media(max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}
.modal-header .btn-close {
    padding: .5rem;
    margin: -.5rem -.5rem -.5rem auto
}
.selectsong-row{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    position: relative;
    .radio-contniner{
        position: relative;
    }
    .maring-left-auto{
        margin-left: 10px;
    }
}
.modal-content {
    max-width: 670px;
    padding: 32px;
    background-color: rgb(26, 26, 26);
    border-bottom-color: rgb(0, 0, 0);
    border-radius: 4px;
    color: #fff;
}
.modal-header {
    padding: 0 0 10px;
    margin-bottom: 20px;
}
.modal-body {
    padding: 0;
}
.selectsong-row .radio-contniner input[type="radio"] {
    position: static;
}
.selectsong-row .ml-auto.p-2.d-flex span svg path {
    stroke: #fff !important;
}
.selectsong-row .ml-auto.p-2.d-flex span {
    position: absolute;
    right: 0;
    cursor: pointer;
}
.modal-header span  {
    cursor: pointer;
}
.modal-header span svg path {
    stroke: #fff !important;
}
.modal-header .modal-title.h4 {
    font-size: 20px;
}


.light-theme {
    .modal-content {
       
        background-color: #fff;
        border-bottom-color: rgb(0, 0, 0);
        color: #535353;
    }
    .modal-header span svg path {
        stroke: #535353 !important;
    }
}