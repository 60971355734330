.middle-section {
	.content-sec {
		.most-download {
			.box {
				display:block;
				img {
					width:260px;
					height:260px;
					border-radius:6px;
					object-fit:cover;
				}
				.download-info {
					margin-top:10px;
					h5 {
						color:$white;
						font-weight:$fw-500;
					}
				}
			}
			.slider-sec {
				.slick-slider {
					padding-bottom: 0;
					.slick-prev, .slick-next {
						display: none !important;
					}
				}
			}
		}
		.latest-spotlights {
			margin-top:32px;
			.latest-spot-block {
				display:flex;
				flex-wrap:wrap;
				justify-content: space-between;
				.box {
					display:block;
					margin-bottom:30px;
					margin-right:9px;
					margin-left:9px;
					max-width:149px;
					img {
						width:149px;
						height:149px;
						border-radius:6px;
						object-fit:cover;
					}
					.latest-info {
						margin-top:10px;
						h5 {
							color:$white;
							font-weight:$fw-500;
						}
					}
					
				}
			}
		}
		
		.pagingnation-sec {
			margin-top:35px;
			ul {
				display:flex;
				li {
				margin-right:4px;
					a {
						background:$background-grey-6;
						border: 1px solid #111316;
						border-radius: 8px;
						height:34px;
						width:34px;
						display:flex;
						align-items:center;
						justify-content:center;
						color:$white;
						transition:ease all 0.5s;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						&:hover {
							background:$orange;
						}
						svg {
							path {
								stroke:$white;
							}
						}
					}
					&.active {
						a {
							background:$orange;
						}
					}
					&.disabled {
						svg {
							path {
								stroke:$grey;
							}
						}
					}
					&.right {
						svg {
							transform:rotate(-180deg);
						}
					}
				}
			}
		}
	}
}

.curated-geners {
	.curated-gen-block {
		padding:20px;
		.box {
			background:$background-grey-4;
			box-shadow: 0px 1px 0px #242629;
			border-radius: 8px;
			padding:12px;
			margin-bottom:12px;
			a {
				display:flex;
				align-items:center;
				justify-content:space-between;
				color:$white;
				svg {
					path {
						stroke:$white;
					}
				}
			}
			.headinggenres {
				display:flex;
				align-items:center;
				justify-content:space-between;
				color:$white;
				svg {
					path {
						stroke:$white;
					}
				}
			}
			ul {
				position:relative;
				padding-top:13px;
				display:none;
				
				li {
					padding-top:14px;
					
				   a {
					font-weight:$fw-400;
					font-size:$fs-14;
					line-height: 18px;
					color:$white;
				   }
				}
				&:after {
					border: 1px solid #222427;
					box-shadow: 0px 1px 0px $background-grey-4;
					content: "";
					position: absolute;
					top: 13px;
					width: 100%;
				}
			}
			&.active {
				a {
					svg {
						transform:rotate(-180deg);
					}
				}
				ul {
					display:block;
				}
			}
		}
	}
	.suggest-collection {
		.top {
			padding: 0 0 20px;
			margin-bottom: 20px;
			&:after {
				width:100%;
			}
		}
		ul {
			li {
				padding:10px;
				
				&:before {
					display:none;
				}
				.content {
					 img {
						margin-left:0;
						margin-right:12px;
						width: 66px;
						height: 66px;
					 }
				}
			}
		}
	}
}


.light-theme {
	.middle-section {
		.content-sec {
			.most-download {

				.box {
					.download-info {
						h5 {
							color:$background-black;
						}
					}
				}
			}
			.latest-spotlights {
				.latest-spot-block {
					.box {
						.latest-info {
							h5 {
								color:$background-black;
							}
						}
					}
				}
			}
			.pagingnation-sec {
				ul {
					li {
						a {
							background: linear-gradient(180deg, #E8E8E8 0%, #E4E4E4 100%);
							border-color: #E4E4E4;
							color:#535353;
							
							svg {
								path {
									stroke:#535353;
								}
							}
							&:hover {
								background:$orange;
								color:$white;
								border-color:$orange;
								svg {
									path {
										stroke:$white;
									}
								}
							}
						}
						&.disabled {
							svg {
								path {
									stroke:#a3a3a3;
								}
							}
						}
						&.active {
							a {
								background:$orange;
								color:$white;
								border-color:$orange;
								svg {
									path {
										stroke:$white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.curated-geners {
		.curated-gen-block {
			.box {
				background:$white;
				box-shadow: 0px 1px 0px #ECECEC;
				a {
					color:$background-grey-6;
				}
				ul {
					&:after {
						border: 1px solid #F5F5F5;
						box-shadow: 0px 1px 0px #DBDBDB;
					}
				}
			}
		}
	}
}