a {
    text-decoration: none;
}
.left-nav {
    .nav-link {
        ul {
            li {
                a {
                   text-decoration: none;
                }
            }
        }
    }
}
.middle-section {
    padding-bottom: 129px;
    .top-header {
        .search {
            .form-control {
                background: #16181B;
                border: 1px solid #282A2D;
                border-radius: 40px;
                padding-left: 35px;
                max-width: 96px;
                transition:all 0.5s;
                 &:focus {
                    max-width: 200px;
                }
            }
            
            span#Search {
                position: absolute;
                left: 10px;
                top: 10px;
            }
        }
        .profile-name {
            a {
                display: flex;
                align-items: center;
            }
        }
    }
    .content-sec {
        .pagingnation-sec {
            ul {
                li {
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
        .slider-sec {
            .box {
                margin-right: 18px;
            }
            .slick-slider {
                margin-bottom: 75px;
                .slick-arrow {
                    bottom: -50px;
                    top: auto;
                    right: 0;
                    left: auto;
                    width: 24px;
                    height: 24px;
                    &.slick-prev {
                        right: 40px;
                        background: url(../images/left-arrow.svg) no-repeat;
                    }
                    &.slick-next {
                        background: url(../images/right-arrow.svg) no-repeat;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .play-list-sec {
            .inner-block {
                .top-list{
                    ul{
                        flex-wrap: wrap;
                        li {
                            margin-bottom:8px;
                            a,span {
                                text-decoration: none;
                                border: 1px solid #828282;
                                border-radius: 8px;
                                padding: 4px 8px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color:#717171;
                                &:hover {
                                    background: #EB4E27;
                                    border-color: #EB4E27;
                                    color: #fff;
                                    font-weight: 500;
                                }
                            }
                            &.active {
                                a,span {
                                    background: #EB4E27;
                                    border-color: #EB4E27;
                                    color: #fff;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
                .play-list {
                    .block {
                        flex-wrap: wrap;
                        .version-container {
                            width: 100%;
                            padding-top: 25px;
                            .block-inner-content {
                                display: none;
                            }
                        }
                        &.active {
                            .version-container {
                                .block-inner-content {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}
.circle {
    display: inline-block;
      background-color: #EB4E27;
      margin: 10px;
      border-radius: 50%;
      .circle-inner {
        color: #000;
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        height: 28px;
        width: 28px;  
        font-size: 1rem;
      }
}
.table-content {
	background: $background-grey-6;
	border-radius: 6px;
	
	table {
		width:100%;
		border-collapse: collapse;
		tr {
			th, td {
				padding:16px;
				font-weight:$fw-600;
				font-size:$fs-12;
				line-height: 15px;
				color:#a3a3a3;
				text-align:left;
			}
			th {
				border-bottom: 1px solid #1D1F22;
			}
			td {
                color:$white;
                a {
                    color:$orange;
                }
			}
		}
		
	}
}
.account-info-sec {
    .content-sec {
        .account-block {
            .account-inner-block {
                .ticket-sec {
                    .inner-ticket-block {
                        .left {
                            .ticket-query {
                                .box {
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                            .form-group {
                                .form-control[type="file"] {
                                    background: none;
                                    border: none;
                                    height: auto;
                                    padding: 0;
                                }
                            }
                            .secondary-btn {
                                max-width: 330px;
                              }
                        }
                        .right {
                            .body-content {
                                .d-flex.justify-content-between {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 10px;
                                }
                                .ticket-number {
                                    color: #a3a3a3;
                                    margin-right: 10px;
                                }
                                .action {
                                    a.status-Open {
                                        color: $orange;
                                    }
                                }
                            }
                        }
                    }
                }

                .form-content {
                    & > div.upload-checkbox-section {
                        flex-wrap: wrap;
                       & > div {
                            width: calc(33.33% - 10px);
                            margin-bottom: 20px;
                            h3 {
                                border-bottom: 1px solid #fff;
                                padding: 0 0 5px;
                                margin-bottom: 10px;
                            }
                            .card-body {
                                & > div {
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
                .upload-song-list {
                    .form-content {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .form-group {
                        display: block;
                        margin-right:8px;
                        width: calc(33.33% - 8px);
                        & > div {
                            display: flex;
                            align-items: center;
                            input.form-control {
                                width: auto;
                                margin-right: 10px;
                            }
                            span {
                                cursor: pointer;
                            }
                        }
                        button {
                            cursor: pointer;
                        }
                       .selected-song-sec {
                            display: block;
                            .d-flex {
                                .time-control {
                                    width: 60px;
                                }
                            }
                        }
                    }
                    .d-full-block {
                        width: 100%;
                        .form-group {
                            width: calc(50% - 8px);
                        }
                    }
                }
                
                &.search-result {
                    .form-content {
                        display: flex;
                        flex-wrap: wrap;
                        .form-group {
                            margin-right: 8px;
                            max-width: calc(33.33% - 8px);
                        }
                    }
                }
            }
           
        }
    }
}

.chat-boat {
    .reply-msg {
        background: #16181B;
        box-shadow: 0px 1px 0px #242629;
        border-radius: 6px;
        padding: 10px;
        width: 70%;
        &.reply-customer {
            float: right;
            margin-top: 20px;
            background: #727171;
            color: #000;
            margin-bottom: 20px;
        }
        
        &.reply-admin {
            float: left;
        }
        .reply-date {
            color: #a3a3a3;
            margin: 3px 0 0;
            font-size: 12px;
        }
    }
}

/** Spinner CSS **/
.spinner-border,.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid;
    border-right-color: transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .spinner-border,.spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}
.text-danger, .invalid-feedback {
    --bs-text-opacity: 1;
    color: #EB4E27;
}
.alert.alert-success {
    color: green;
    padding: 5px;
    border-color: green;
    border: 1px solid;
}
.success-text {
    color: green !important;
}
.alert.alert-danger{
    color: #ee3935;
    padding: 10px;
    border: 1px solid #ee3935;
    margin-bottom: 10px;
}
.response-msg{
    display: block;
    min-width: 100%;
}
.mt-1{
        margin-top: 10px;
}
.mb-1{
    margin-bottom: 10px;
}
.t-sub{ font-weight: 900; margin-bottom: 2px;}
.react-tooltip a {
    color: #fff;
}

.popup-content {
    max-width: 670px;
    padding: 32px !important;
    background-color: rgb(26, 26, 26)!important;
    border-bottom-color: rgb(0, 0, 0);
    border-radius: 4px;
    position: absolute;
    width: 100%;
    z-index: 10;
    border: none !important;
    .inner-block {
        color:$white;
        .playlist-albums {
            li {
                display: flex;
                align-items: center;
                margin: 10px 0 0;
                span  {
                    margin-right:10px;
                    &:first-child{
                        cursor: pointer;
                    }
                }
                .playlist-album-img{
                    max-width: 50px;
                    position: relative;
                    .center-spinner{
                        position: absolute;
                        top: 35%;
                        left: 30%;
                    }
                }
            }
        }
		.top-section {
			display:flex;
			justify-content:space-between;
			span {
				cursor:pointer;
			}
		}
		.form-content {
            .filepond--root.filepond--hopper {
                margin: 10px 0;
            }
			p {
				color:$white;
			}
			input {
				display: block;
				font-size: 14px;
				height: 40px;
				background: transparent;
				border: none;
				border-bottom: 1px solid $grey;
				color: $grey;
				max-width: 300px;
                width: 100%;
                border-radius: 0;
                padding-left:0;
				
				&:focus-visible {
					outline: none;
					border-bottom: 1px solid $white;
					color: $white;
				}
				&:focus {
					outline: none;
                }
                
            }
            label.text-3.form-label {
                color: #fff;
                display: block;
                margin: 15px 0;
            }
			.btn-sec {
				display:flex;
				margin-top: 40px;
				button {
					width: auto;
					padding: 0 25px;
					margin-right:15px;
					cursor:pointer;
				}
			}
		}
	}
}
.top-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .search-items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 400px;
    }
}

.upload-checkbox-section + button.secondary-btn {
    width: auto;
    min-width: 100px;
}
.full-section.account-info-sec .content-sec {
    max-width: 100%;
}
.search-items.form-group button.secondary-btn {
    width: auto;
    min-width: 70px;
    margin-left: 10px;
    height: 40px;
}
.top-actions .search-items + div .primary-btn {
    min-width: 190px;
}
.cardMark {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cardMark span {
    font-size: 18px;
    font-weight: 600;
}
.cardMark button.primary-btn {
    width: auto;
    min-width: 150px;
}
.song-counter {
    min-width: 15px;
    max-width: 15px;
}

.light-theme {
    .table-content {
        background: #f9f9f9;
        table {
            tr{
                th {
                    border-bottom: 1px solid #f0f0f0;
                }
                th,td{
                        color: #535353;
                    
                }
            }
        }
    }
    .middle-section {
        .top-header {
            .search {
                .form-control {
                    background: #fff;
                    border: 1px solid #f0f0f0;
                    &:focus {
                        outline-color: #535353;
                    }
                }
            }
        }
    }
}
.cp{
    cursor: pointer;
}
a.filepond--credits {
    display: none;
}
.popup-content .modal {
    position: static;
    display: block;
}
.account-info-sec .content-sec .account-block .account-inner-block .ticket-sec .inner-ticket-block .left {
    margin-right: 16px;
    max-width: calc(100% - 316px);
    width: 100%;
}
.faq-block 
 .accordion-button:after {
    background: url(../images/down-arrow-white.svg) !important;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-size: auto;
}
.middle-section .content-sec .play-list-sec .inner-block .play-list .block .play-track .track-name span {
    margin-left: 20px;
    text-overflow: ellipsis;
    // max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.hover-tooltip {
    border-radius: 3px;
    font-size: 90%;
    padding: 8px 16px;
    pointer-events: none;
    background-color: rgb(235, 78, 39);
    color: rgb(255, 255, 255);
	position:relative;
}
.hover-tooltip a {
    color: #fff;
}
.hover-tooltip:after {
    background: rgb(235, 78, 39);
    height: 8px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 8px;
    content: "";
    bottom: -4px;
}
/* Left Nav CSS */
.left-nav {
	max-width:85px;
	overflow: hidden;
	position: fixed;
	z-index: 9;
	transition: all 0.5s;
}


.left-nav .top-logo svg {
	min-width: 50px;
	width: 100%;
	position: relative;
	left: -20px;
	transition: all 0.5s;
}
.left-nav .nav-link ul li a {
	padding: 16px 10px 16px 40px;
}
.left-nav .nav-link ul li a svg {
	margin-right: 0;
	min-width: 14px;
}
.left-nav .nav-link ul li a span {
	opacity:0;
	white-space: nowrap;
}
.left-nav:hover {
	max-width: 262px;
}
.left-nav:hover .top-logo, .left-nav.active .top-logo{
	padding:30px 40px 30px 40px;
}
.left-nav:hover .top-logo svg, .left-nav.active .top-logo svg {
	min-width: 78px;
	left: 0;
	width:auto;
}
.left-nav:hover .nav-link ul li a, .left-nav.active .nav-link ul li a  {
	padding:16px 40px 16px 40px;
}
.left-nav:hover .nav-link ul li a span, .left-nav.active .nav-link ul li a span  {
	opacity:1;
}
.left-nav:hover .nav-link ul li a svg, .left-nav.active .nav-link ul li a svg {
	margin-right: 16px;
}
.left-nav.active {
	max-width: 262px;
	position: static;
	transition: all 0.5s;
}
.left-nav-text{
    font-size: 12px;
}
.middle-section {
	width: calc(100% - 301px);
	background: #16181B;
	padding-left: 86px;
	box-sizing: border-box;
}
.full-section {
	width: 100%;
}

.middle-section.active {
	width: calc(100% - 568px);
	padding-left: 0;
}
.full-section.active {
	width: calc(100% - 263px);
}
.middle-section .top-header {
	justify-content: space-between;
}
.middle-section .top-header > div {
	display: flex;
}
/***************** popup css *********************/
.popup-content ul.playlist-albums {
    display: flex;
    flex-wrap: wrap;
}
.popup-content ul.playlist-albums li.user-playlist-albums {
    width: 33.33%;
}
.popup-content .inner-block .playlist-albums li .playlist-album-img {
    max-width: 25px;
    position: relative;
    margin-right: 10px;
}

.swal2-popup {
    background-color: rgb(26, 26, 26);
    border-bottom-color: rgb(0, 0, 0);
}
.swal2-popup h2.swal2-title {
    color: #fff;
}
.swal2-popup .swal2-html-container {
    color: #fff;
}
.swal2-popup .swal2-styled.swal2-confirm {
    background-color: #EB4E27;
}
.swal2-popup .swal2-styled.swal2-cancel {
    background: #fff;
    color: #080A0D;
}
.light-theme .swal2-popup {
    background-color: #fff;
    border-bottom-color: #fff;
}
.light-theme .swal2-popup h2.swal2-title {
    color: #000;
}
.light-theme .swal2-popup .swal2-html-container {
    color: #000;
}
.light-theme .swal2-popup .swal2-styled.swal2-cancel {
    background: #000;
    color: #fff;
}
.dark-theme .swal2-popup, .swal2-popup {
    color: #fff !important;
    background: #000 !important;
}
.light-theme .swal2-popup {
    background-color: #fff !important;
    border-bottom-color: #fff !important;
    color: #000 !important;
}
/************************** end *************************/


/****************** left nav css********************/
.light-theme .burger-menu svg path {
    stroke: #000;
}
.left-nav {
    height: calc(100vh - 50px);
    overflow-y: scroll;
}
.left-nav .top-logo {
    min-height: 64px;
}
.left-nav .top-logo img {
    min-width: 50px;
    width: 100%;
    position: relative;
    left: -20px;
}
.left-nav:hover .top-logo img, .left-nav.active .top-logo img {
    min-width: 78px;
    left: 0;
    width: auto;
    max-width: 100px;
}
.left-nav .theme-mode .inner-mode {
    width: auto;
}
.left-nav .theme-mode .inner-mode .mode .text-5 {
    display: none;
}
.left-nav .theme-mode .inner-mode .mode svg {
    margin-right: 0;
}
.left-nav .theme-mode .inner-mode .mode {
    width: 25px;
	height: 25px;
}

.left-nav:hover .theme-mode .inner-mode, .left-nav.active .theme-mode .inner-mode  {
    width: 182px;
}
.left-nav:hover .theme-mode .inner-mode .mode .text-5, .left-nav.active .theme-mode .inner-mode .mode .text-5 {
    display: block;
}
.left-nav:hover .theme-mode .inner-mode .mode svg, .left-nav.active .theme-mode .inner-mode .mode svg  {
    margin-right: 9px;
}
.left-nav:hover .theme-mode .inner-mode .mode, .left-nav.active .theme-mode .inner-mode .mode {
   width: 84px;
   height: 34px;
}

/********************* end **************************/



/********************* edit profile*************************/

.light-theme .account-info-sec .content-sec .account-block .account-inner-block .title-heading .log-link {
    color: #080a0d;
}
.edit-photo .filepond--wrapper {
    padding-left: 10px;
    position: relative;
    top: 5px;
}
.edit-photo>span .filepond--drop-label label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: underline;
    min-width: 90px;
    padding: 0;
    color: #fff;
	margin-left: 35px;
}
.light-theme .edit-photo>span .filepond--drop-label label {
   color: #080a0d;
}

.light-theme span.lnir.lnir-trash svg {
    fill: #828282;
}


/**************** sign in********************/

.light-theme .sign-in .sign-in-left .form-sec .fgp {
    color: #828282;
}

/* edit profile light dark theme */
.light-theme .edit-photo>span .filepond--drop-label label {
    color: #080a0d;
 }
 
 .light-theme span.lnir.lnir-trash svg {
     fill: #828282;
 }
 
 .light-theme .filepond--file-status-main, .light-theme .filepond--file-status-sub, .light-theme span.filepond--file-info-sub {
     color: #000;
 }
 /* for mobile */
 .for-mobile{
     display: none;
 }

 .announcement-bar{
    background: #eb4e27;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    color: #fff;
    position: relative;
    span{
        position: absolute;
        right: 5px; 
        top: 5px;
        cursor: pointer;
    }
}
.light-theme .curated-geners .curated-gen-block .box h3 {
    color: #282a2d;
}
.light-theme .curated-geners .curated-gen-block .box .headinggenres svg path {
    stroke: #282a2d;
}
.light-theme .faq-block .accordion-button:after {
    background: url(../images/down-arrow-black.svg)!important;
}

/* Dropbox Header and popup */
#dropbox-btn {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	height: 100vh;
	width: 100vw;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(17,17,25,0.8);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	transition-property: opacity;
	transition-duration: .15s;
	transition-timing-function: cubic-bezier(.4,0,.2,1);
	z-index: 9999;
	opacity:0;
	display:none;
}
#dropbox-btn.show {
	opacity:1;
	display:flex;
} 
#dropbox-btn h2 {
	font-size: 32px;
	color: #fff;
}

#dropbox-btn h2 span {
	color: #EB4E27;
	font-weight: 700;
	background: -webkit-linear-gradient(#EB4E27, #EB4E27);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.dropbox-dropin-btnn {
	border: 1px solid #fff;
	border-radius: 8px;
	padding: 15px 30px;
	display: inline-block;
	margin: 20px 0;
	color: #fff;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.dropbox-dropin-btnn img, .dropbox-dropin-btnn svg {margin-right:10px; }
.db-secondary-btns a {
	color: #fff;
	opacity: 0.6;
	margin: 0 5px;
}
.db-secondary-btns a img {
	margin-right: 5px;
}


.dropbox-sec .theme-mode {
  margin:2px 10px 0 0;
  display: inline-block;
}
.dropbox-sec .theme-mode .inner-mode {
  background: #080A0D;
  border: 1px solid #1D1F22;
  border-radius:18px;
  width:86px;
  height: 28px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.dropbox-sec .theme-mode .inner-mode .mode {
  display: flex;
  align-items: center;
  border-radius: 18px;
  width: 42px;
  height: 26px;
  justify-content: center;
  cursor: pointer;
  box-shadow: 2px 2px 6px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  transition: all 0.5s;
}

.dropbox-sec .theme-mode .inner-mode .mode svg path {
  stroke: #828282;
}
.dropbox-sec .theme-mode .inner-mode .mode .text-5 {
  color: #828282;
}
.dropbox-sec .theme-mode .inner-mode .mode.active {
  background: linear-gradient(101.43deg, #181A1D 7%, #0A0C0F 89.6%);
  border: 1px solid #1D1F22;
}
.dropbox-sec .theme-mode .inner-mode .mode.active .text-5 {
  color: #EB4E27;
}
.dropbox-sec .theme-mode .inner-mode .mode.active svg path {
  stroke: #EB4E27;
}
.dropbox-notification {
	width: 30px;
	display: inline-block;
	position: relative;
	margin-right: 0;
}
.dropbox-notification span {
	position: absolute;
	top: 0;
	background: red;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	color: #fff;
	font-size: 8px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 5px;
}

/* Loading-wrapper */
.middle-section .content-sec .play-list-sec .inner-block .top-heading {
    margin-top: 20px;
}
.popup-content .inner-block .playlist-albums li .playlist-album-img {
    max-width: 125px;
	margin-right: 0;
}
.popup-content .inner-block .playlist-albums li {
    display: flex;
    margin: 0 0 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.popup-content .inner-block .playlist-albums li .playlist-album-name {
    display: block;
    width: 100%;
    text-align: center;
    margin: 5px 0;
}
.popup-content .inner-block .playlist-albums li svg {
    width: 40px;
    height: 40px;
}
.light-theme .dropbox-sec .theme-mode .inner-mode {
    background: #efefef;
    border-color: #e8e8e8;
}
.light-theme .dropbox-sec .theme-mode .inner-mode .mode.active {
    background: linear-gradient(101.43deg,#f8f8f8 7%,#f5f5f5 89.6%);
    border-color: #e7e7e7;
}
.light-theme .dropbox-sec .theme-mode .inner-mode .mode {
    box-shadow: none;
}
.light-theme .middle-section .content-sec .slider-sec .box h5 {
    color: #080a0d;
}


.text-icon {
    height: 10px;
    margin: 0 10px;
    width: 50px;
}

.text-icon-list {
    display: flex;
}

.wrapper-row {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    margin: 4px 0;
}

.text-line:last-child {
    width: 150px;
}

.wrapper-image .image {
    width: 149px;
    height: 149px;
    margin-right: 18px;
}

.wrapper-row-image {
    height: 149px;
}
.wrapper-multiple-image {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
    height: auto;
}
.wrapper-multiple-image .wrapper-image .image {
    
    margin-bottom: 30px;
}
.animated-background, .image, .text-line, .text-icon {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #1C1E21;
    background: linear-gradient(90deg, #1C1E21 8%, #1C1E21 18%, #25282d 33%);
    background-size: 800px 104px;
    height: 40px;
    position: relative;
    
}
.light-theme .animated-background, .light-theme .image, .light-theme .text-line, .light-theme .text-icon {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(90deg,#c7c7c7 8%,#b1aeae 18%,#a3a2a2 33%);
    background-size: 800px 104px;
    height: 40px;
    position: relative;
    
}
// Animation
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

// Page Elements
.image {
    height: 60px;
    width: 60px;
  //  background: #F6F6F6;
    @extend .animated-background;
  }
  
  .text {
    margin-left: 20px
  }
  
  .text-line {
    height: 10px;
    width: 230px;
  //  background: #F6F6F6;
    margin: 4px 0;
    @extend .animated-background;
  }
.text-icon {
    height: 20px !important;
    width: 30px !important;
}
.wrapper-cell {
    display: flex;
    align-items: center;
}
.wrapper-cell .image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
}
.text-line {
    height: 10px !important;
    margin-bottom: 5px;
    width: 200px;
}