
.main-page {
	display:flex;
}
body{
	background: #161818;
}
body.light-theme{
	background: #F5F5F5;
}
.left-nav {
	background:$background-dark-grey;
	max-width: 262px;
	border-right: 1px solid #030508;
	width: 100%;
	/*height: 100vh;*/
	
	.top-logo {
		padding: 30px 40px 30px 40px;
		.close-nav{
			display: none;
		}
	}
	.nav-link {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		
		&:after {
			content: "";
			border: 1px solid #080A0D;
			box-shadow: 0px 1px 0px #1A1C1F;
			width: 182px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50% , 0);
		}
		ul {
			li {
				a {
					display: flex;
					align-items: center;
					padding: 16px 40px 16px 40px;
					position:relative;
					text-transform: uppercase;
					&:before {
						content:"";
						position: absolute;
						width: 0;
						height: 24px;
						left: 0px;
						top: 14px;
						background:$orange;
						box-shadow: 8px 0px 20px rgba(235, 78, 39, 0.6);
						border-radius: 0px 4px 4px 0px;
						transition:ease all 0.5;
					}
					
					svg {
						margin-right:16px;
					}
					&:hover {
						&:before {
							width:6px;
						}
						svg {
							path {
								stroke:$white;
							}
						}
						.text-1 {
							color:$white;
						}
					}
				}
				&.active {
					a {
						&:before {
							width:6px;
						}
						svg {
							path {
								stroke:$white;
							}
						}
						.text-1 {
							color:$white;
						}
					}
				}
			}
		}
	}
	.theme-mode {
		margin: 20px 0 0;
		padding-bottom: 20px;
		.inner-mode {
			background:$background-black;
			border: 1px solid #1D1F22;
			border-radius: 25px;
			width: 182px;
			height: 36px;
			padding:2px;
			display:flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 auto;
			
			.mode {
				display: flex;
				align-items: center;
				border-radius: 25px;
				width: 84px;
				height: 34px;
				justify-content: center;
				cursor:pointer;
				box-shadow: 2px 2px 6px 6px rgba(0, 0, 0, 0.15);
				border: 1px solid transparent;
				transition: all 0.5s;
				
				svg {
					margin-right:9px;
					path {
						stroke:$grey;
					}
				}
				.text-5{
					color:$grey;
				}
				&.active {
					background:$background-linear;
					border: 1px solid #1D1F22;
					.text-5 {
						color:$orange;
					}
					svg {
						path {
							stroke:$orange;
						}
					}
				}
			}
		}
	}
}

.right-trending, .for-desktop {
	background: $background-grey-5;
	border-left: 1px solid #030508;
	box-shadow: -1px 0px 0px $background-grey-5;
	width: 100%;
	max-width: 300px;
	// height: 100%;
	min-height: 100vh;
	.top {
		padding: 30px 20px 20px;
		display: flex;
		align-items: center;
		position:relative;
		
		&:after {
			border: 1px solid #16181B;
			box-shadow: 0px 1px 0px #242629;
			content:"";
			content: "";
			position: absolute;
			bottom: 0;
			width: 260px;
		}
		h2 {
			color:$white;
			margin-right:10px;
		}
	}
	.track-list {
		padding: 20px;
		 ul {
			counter-reset: section;
			li {
				background:$background-grey-4;
				box-shadow: 0px 1px 0px #242629;
				border-radius: 8px;
				padding: 16px 10px;
				margin-bottom: 8px;
				display: flex;
				align-items: center;
				.song-counter{
					.song-number{
						color: #F4FD84;
						font-size: 12px;
						line-height: 15px;
						font-weight: 600;
						min-width: 15px;
						text-align: center;
					}
					.play-pause{
						display: none;
					}	
				}
				&:hover {
					.song-number{
						display: none;
					}
					.play-pause{
						display: block;
					}	
				}
				// &:before {
				// 	counter-increment: section;
				// 	content: counters(section,".") " ";
				// 	color: #F4FD84;
				// 	font-size: 12px;
				// 	line-height: 15px;
				// 	font-weight: 600;
				// 	min-width: 15px;
				// 	text-align: center;
				// }
				.content {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					& > div {
						display: flex;
						align-items: center;
					}
					img {
						margin:0 8px;
						width: 40px;
						height: 40px;
						border-radius: 6px;
						object-fit: cover;
					}
					.track-name {
						margin-right: 10px;
						max-width:140px;
						& > div {
							text-overflow: ellipsis;
							overflow: hidden;
							display: block;
							width: 100%;
							white-space: nowrap;
						}
						.text-5 {
							color:#A3A3A3;
							margin-top:6px;
						}
					}
				}
				
				&.active {
					background:$background-grey-6;
					.song-number{
						display: none;
					}
					.play-pause{
						display: block;
					}
					// &:before {
					// 	content: "";
					// 	border: 7px solid #fff;
					// 	border-top: 7px solid transparent;
					// 	border-bottom: 7px solid transparent;
					// 	border-right: 7px solid transparent;
					// 	counter-increment: none;
					// 	min-width: inherit;
					// 	position: relative;
					// 	left: 4px;
					// }
				}
			}
		 }
	}
}



.middle-section {
	width:calc(100% - 562px);
	background:$background-grey-4; 
	
	.top-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 30px;
		.search {
			position: relative;
			margin-right: 16px;
			padding-right: 16px;
			&:after {
				border: 1px solid #111316;
				box-shadow: 1px 0px 0px #26282B;
				content: "";
				position: absolute;
				right: 0;
				height: 20px;
				top: 50%;
				transform: translate(0 , -50%);
			}
			.search-text-icon {
				background:$background-grey-4;
				border: 1px solid #282A2D;
				border-radius: 40px;
				width: 93px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				
				svg {
					margin-right:6px;
				}
			}
		}
		.active-download-method {
			position: relative;
			margin-right: 16px;
			padding-right: 16px;
			cursor: pointer;
			span{
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
			}
			& .dropbox-icon	{
				position: relative;
				display: inline-block;
				margin-left: 10px;
				span{
					position: absolute;
					background: #FFF;
					width: 20px;
					text-align: center;
					border-radius: 50%;
					right: -10px;
					top: -7px;
				}
			}
		}
		.profile-name {
			background:$background-black;
			border-radius: 40px;
			display:flex;
			align-items:center;
			width: 113px;
			height: 32px;
			padding: 0 4px;
			
			img {
				width:28px;
				height:28px;
				border-radius:50%;
				object-fit:cover;
				margin-right:7px;
			}
		}
	}
	
	.content-sec {
		padding: 0 30px 30px;
		.top-heading {
			display:flex;
			justify-content:space-between;
			align-items:center;
			margin-bottom: 16px;
			h2 {
				color:$white;
				text-transform: uppercase;
			}
			a {
				text-decoration:underline;
			}
		}
		.slider-sec {
			.box {
				position:relative;
				img {
					width:149px;
					height:149px;
					border-radius:8px;
					overflow:hidden;
					object-fit: cover;
				}
				h5 {
					background: rgba(255, 255, 255, 0.2);
					backdrop-filter: blur(10px);
					border-radius: 0px 0px 8px 8px;
					color:$white;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: 10px 15px;
					box-sizing: border-box;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: block;
					text-align:center;
				}
			}
			.owl-nav {
				margin-top: 20px;
				text-align: right;
				button {
					width: 24px;
					height: 24px;
					margin: 0 0 0 12px;
					text-indent: -9999px;
				}
				.owl-next {
					background:url(../images/right-arrow.svg) no-repeat;
				}
				.owl-prev {
					background:url(../images/left-arrow.svg) no-repeat;
				}
			}
			&.top-twenty {
				margin-top:32px;
				.box {
					background:$background-dark-grey;
					box-shadow: 0px 1px 0px #242629;
					border-radius: 8px;
					padding:8px;
					
					img {
						width:133px;
						height:133px;
					}
					
					h5 {
						position: static;
						background: none;
						padding:10px 0 8px;
					}
				}
			}
			&.merchandise {
				.box {
					
					p {
						padding:10px 0 8px;
						max-width:133px;
						margin: 0;
						min-height: 45px;
					}
				}
			}
		}
		
		.play-list-sec {
			margin-top:35px;
			.inner-block {
				background:$background-grey-5;
				border-radius: 8px;
				overflow: hidden;
				.top-list {
					padding: 24px 16px 16px;
					ul{
						display: flex;
						li {
							margin-right:8px;
							a {
								border: 1px solid $grey;
								border-radius: 8px;
								padding:4px 8px;
								display:flex;
								align-items:center;
								justify-content:center;
								&:hover {
									background:$orange;
									border-color:$orange;
									color:$white;
									font-weight:$fw-500;
								}
							}
							&.active {
								a {
									background:$orange;
									border-color:$orange;
									color:$white;
									font-weight:$fw-500;
								}
							}
						}
					}
				}
				.play-list {
					.block {
						display: flex;
						align-items: center;
						justify-content: space-between;
						position:relative;
						padding: 16px 20px;
						
						&:after {
							border: 1px solid #222427;
							box-shadow: 0px 1px 0px #16181B;
							content:"";
							position:absolute;
							bottom:0;
							width: calc(100% - 40px);
							box-sizing: border-box;
						}
						
						.play-track {
							display: flex;
							align-items: center;
							
							.track-name {
								margin-left:20px;
								span {
									display:block;
								}
								.text-2 {
									color:$white;
									margin-bottom:6px;
								}
								.text-5 {
									color:#A3A3A3;
								}
							}
						}
						.play-track-content {
							display: flex;
							align-items: center;
							
							& > div {
								display: flex;
								align-items: center;
								margin:0 10px;
								
								&.divider {
									position:relative;
									padding-right: 20px;
									&:after {
										content:"";
										border: 1px solid #111316;
										box-shadow: 1px 0px 0px #26282B;
										position: absolute;
										height: 20px;
										right:0;
									}
								}
								
								span {
									margin:0 15px;
								}
								a {
									margin:0 10px;
								}
								.text-5 {
									color:#A3A3A3;
								}
								&:last-child {
									margin:0;
									min-width: 50px;
								}
							}
						}
						
						&.active {
							flex-wrap:wrap;
							background:$background-grey-6;
							
							&:after {
								border: 1px solid #1D1F22;
								box-shadow: 1px 0px 0px #26282B;
								bottom: auto;
								top: 70px;
							}
							.play-track {
								a {
									svg {
										path {
											fill:$orange;
										}
									}
								}
							}
							.play-track-content {
								div {
									&.divider {
										&:after {
											border: 1px solid #1D1F22;
											box-shadow: 1px 0px 0px #26282B;
										}
									}
								}
							}
							
						}
						.block-inner-content {
							width: 100%;
							padding-top:8px;
							.block {
								padding:10px 0 10px 30px;
								&:after {
									display:none;
								}
								&:last-child {
									padding-bottom:0;
								}
								.play-track {
									a {
										svg {
											path {
												fill:$white;
											}
										}
									}
									.track-name {
										.text-2 {
											font-weight:$fw-400;
										}
									}
								}
								.play-track-content {
									div {
										&.divider {
											&:after {
												border: 1px solid #1D1F22;
												box-shadow: 1px 0px 0px #26282B;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

}


.play-bar {
	background:$background-grey-5;
	position:relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px 30px 24px;
	
	&:after {
		border: 1px solid #111316;
		box-shadow: 0px 1px 0px #242629;
		content: "";
		position: absolute;
		top: 0;
		left:0;
		width: 100%;
		box-sizing: border-box;
	}
	.song-name {
		h3 {
			color:$white;
			margin-bottom:6px;
		}
		span {
			color:#A3A3A3;
		}
	}
	.middle-bar {
		display: flex;
		align-items: center;
		
		.play-icon-bar {
			display: flex;
			align-items: center;
			position:relative;
			margin-right:30px;
			padding-right:30px;
			&:after {
				content:"";
				border: 1px solid #111316;
				box-shadow: 1px 0px 0px #26282B;
				position:absolute;
				right:0;
				height:40px;
			}
			a {
				margin:0 10px;
				
				&.play-btn {
					background: #16181B;
					box-shadow: 0px 1px 0px #242629;
					border-radius: 20px;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		.time-wave-bar {
			display:flex;
			align-items:center;
			.time {
				display:flex;
				align-items:center;
				margin-right: 11px;
				
				.text-5 {
					color:#A3A3A3;
					margin-left: 3px;
				}
			}
		}
	}
	.right-bar {
		display: flex;
		align-items: center;
		position: relative;
		margin-left: 30px;
		padding-left: 20px;
			
		&:after {
			content:"";
			border: 1px solid #111316;
			box-shadow: 1px 0px 0px #26282B;
			position:absolute;
			left:0;
			height:40px;
		}
		.song-icon {
			
			a {
				margin:0 10px;
			}
			
		}
		.volume-bar {
			display: flex;
			align-items: center;
			position: relative;
			margin-left: 30px;
			padding-left: 30px;
			
			&:after {
				content:"";
				border: 1px solid #111316;
				box-shadow: 1px 0px 0px #26282B;
				position:absolute;
				left:0;
				height:40px;
			}
			a {
				margin-right:12px;
			}
			.volume-progress {
				background:$background-black;
				border-radius: 2px;
				width:120px;
				height:2px;
				
				.volume-progress-inner {
					background:$orange;
					border-radius:2px;
					width: 40%;
					height: 2px;
					transition: all 0.5s;
				}
			}
		}
	}
}


.light-theme {
	.left-nav {
		background:$background-white-1;
		border-right: 1px solid #F0F0F0;
		box-shadow: 1px 0px 0px #E5E5E5;
		
		.nav-link {
			 ul {
				 li {
					 a {
						color: #535353;
						.text-1 {
							color: #535353;
							text-transform: uppercase;
						}
						svg {
							path {
								stroke:#535353;
							}
						}
						&:hover {
							.text-1 {
								color:$background-black;
							}
							svg {
								path {
									stroke:$background-black;
								}
							}
						}
					 }
					 &.active {
						a {
							.text-1 {
								color:$background-black;
							}
							svg {
								path {
									stroke:$background-black;
								}
							}
							
						}
					 }
				 }
			 }
			&:after {
				border: 1px solid #F0F0F0;
				box-shadow: 0px 1px 0px #DBDBDB;
			}
		}
		.theme-mode {
			.inner-mode {
				background:$background-grey;
				border-color:#E8E8E8;
				.mode {
				box-shadow:none;
					&.active {
						background: linear-gradient(101.43deg, #F8F8F8 7%, #F5F5F5 89.6%);
						border-color: #e7e7e7;
					}
				}
			}
		}
	}
	
	.right-trending {
		background:$background-white-1;
		border-left: 1px solid #F5F5F5;
		box-shadow: -1px 0px 0px #E5E5E5;
		.top {
			h2 {
				color:$background-black;
				text-transform: uppercase;
			}
			&:after {
				border: 1px solid #F0F0F0;
				box-shadow: 0px 1px 0px #DBDBDB;
			}
		}
		.track-list {
			ul{
				li {
					background:$white;
					box-shadow: 0px 1px 0px #F0F0F0;
					.song-counter{
						.song-number{
							color:#EB4E27;
						}
					}
					&:before {
						color: $background-black;
					}
					.content {
						 .track-name {
							.text-2 {
								color:$background-black;
							}
							.text-5 {
								color:$grey;
							}
						 }
						 a {
							svg {
								path {
									stroke:$background-black;
								}
							}
						 }
					}
					&.active {
						background: #282A2D;
						background: #FFF4F1;
						box-shadow: 0px 1px 0px #FCECE7;
						&:before {
							border: 7px solid $orange;
							border-top: 7px solid transparent;
							border-bottom: 7px solid transparent;
							border-right: 7px solid transparent;
						}
					}
				}
			}
		}
	}
	.middle-section {
		background: #F5F5F5;
		.top-header {
			.search {
				.search-text-icon {
					border: 1px solid #F0F0F0;
					box-shadow: 0px 1px 0px $white;
					background:$white;
					.text-5 {
						color:$light-grey;
					}
					svg {
						path {
							stroke:$background-grey-6;
						}
					}
				}
				&:after {
					border: 1px solid #F0F0F0;
					box-shadow: 1px 0px 0px $white;
				}
			}
			.profile-name {
				border: 1px solid #F0F0F0;
				box-shadow: 0px 1px 0px $white;
				background:$white;
				.text-5 {
					color:$background-dark-grey;
				}
			}
			
		}
		.content-sec {
			.top-heading {
				h2 {
					color:$background-black;
					text-transform: uppercase;
				}
				a {
					color:$background-black;
				}
			}
			.play-list-sec {
				.inner-block {
					background:$white;
					border: 1px solid #F0F0F0;
					box-shadow: 0px 1px 0px #ECECEC;
					.play-list {
						.block {
							&:after {
								border: 1px solid #FBFBFB;
								box-shadow: 1px 0px 0px #F1F1F1;
							}
							.play-track {
								a {
									svg {
										path {
											fill:$background-black;
										}
									}
								}
								.track-name {
									.text-2 {
										color:$background-black;
									}
									.text-2 {
										color:$grey;
									}
								}
							}
							.play-track-content {
								& > div {
									.text-4 {
										color:$background-black;
									}
									a {
										svg {
											path {
												stroke:$background-grey-6;
											}
										}
									}
									&.divider {	
										&:after {
											border: 1px solid #FBFBFB;
											box-shadow: 1px 0px 0px #F1F1F1;
										}
									}
								}
							}
							&.active {
								background:$white;
							}
							.block-inner-content {
								.block {
									.play-track {
										a {
											svg {
												path {
													fill:$background-black;
												}
											}
										}
									}
									.play-track-content {
										& > div {
											&:first-child {
												a {
													svg {
														path {
															stroke:$orange;
														}
													}
												}
											}
											&.divider {
												&:after {
													border: 1px solid #FBFBFB;
													box-shadow: 1px 0px 0px #F1F1F1;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			
			
			.slider-sec {
				&.top-twenty {
					.box {
						background:$white;
						border: 1px solid #F0F0F0;
						box-shadow: 0px 1px 0px #ECECEC;
						h5 {
							color: #0E1013;
						}
					}
				}
				&.merchandise {
					.box {
						p {
							color: #0E1013;
						}
					}
				}
			}
		}
	}
	.play-bar {
		background:$background-white-1;
		border: 1px solid #F0F0F0;
		&:after {
			display:none;
		}
		.song-name {
			h3 {
				color:$background-black;
			}
			span {
				color:$grey;
			}
		}
		.middle-bar {
			 .play-icon-bar {
				a {
					svg {
						path {
							fill:$grey;
						}
					}
					&:nth-child(2n) {
						svg {
							path {
								stroke:$grey;
								fill: none;
							}
						}
					}
					&.play-btn {
						background: linear-gradient(180deg, #E3E3E3 0%, #F8F8F8 100%);
						box-shadow: 0px 1px 0px #dcdcdc;
						svg {
							path {
								stroke:none;
								fill: $orange;
							}
						}
					}
				}
				&:after {
					border: 1px solid #F0F0F0;
					box-shadow: 1px 0px 0px $white;
				}
			 }
			 .time-wave-bar {
				.time {
					.text-4 {
						color:$background-dark-grey;
					}
					.text-5 {
						color:$light-grey;
					}
				}
				.wave-bar {
					a {
						svg {
							rect[fill="white"] {
								fill: #a3a3a3;
							}
							rect[fill="#282A2D"] {
								fill:$light-grey;
							}
						}
					}
				}
			 }
		}
		.right-bar {
			.song-icon {
				a {
					svg {
						path {
							stroke:$grey;
						}
					}
				}
			}
			.volume-bar {
				a {
					svg {
						path {
							stroke:$grey;
						}
					}
				}
				.volume-progress {
					background: linear-gradient(180deg, #E8E8E8 0%, #E4E4E4 100%);
					box-shadow: 0px 1px 0px $white;
				}
				&:after {
					border: 1px solid #F0F0F0;
					box-shadow: 1px 0px 0px $white;
				}
			}
			&:after {
				border: 1px solid #F0F0F0;
				box-shadow: 1px 0px 0px $white;
			}
		}
	}
}