.library-section {
	.content-sec {
		.top-tab {
			display:flex;
		}
		.favourite-library {
			margin-top:32px;
			.box {
				img {
					width:150px;
					height:150px;
				}
				h5 {
					background: none;
					position: static;
					text-align: left;
					padding: 0;
					text-overflow: inherit;
					backdrop-filter: inherit;
					white-space: normal;
					margin: 9px 0 4px;
				}
				span.text-5 {
					color:$light-grey;
				}
				
			}
		}
	}
}