.my-playlist-sec {
	.top-heading {
		.primary-btn {
			width: auto;
			min-width: 172px;
			font-weight:$fw-400;
			cursor:pointer;
		}
	}
	.playlist-block {
		.playlist-inner-block {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -12px;
			.box {
				padding:0 12px;
				margin-bottom:25px;
				img {
					border-radius: 8px;
					overflow: hidden;
					object-fit: cover;
					height: 261px;
					width: 261px;
				}
				p {
					margin:10px 0 0;
					font-weight:$fw-600;
					font-size:$fs-12;
					line-height: 15px;
					color:$white;
				}
			}
		}
	}
}

.overlay-shadow {
	background: rgba(0,0,0,0.5);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}
.folder-popup {
	max-width: 670px;
	padding: 32px;
	background-color: rgb(26, 26, 26);
	border-bottom-color: rgb(0, 0, 0);
	border-radius: 4px;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	width: 100%;
	left: 50%;
	z-index:10;
	display:none;
	
	.inner-block {
		color:$white;
		.top-section {
			display:flex;
			justify-content:space-between;
			span {
				cursor:pointer;
			}
		}
		.form-content {
			p {
				color:$white;
			}
			input {
				display: block;
				font-size: 14px;
				height: 40px;
				background: transparent;
				border: none;
				border-bottom: 1px solid $grey;
				color: $grey;
				max-width: 300px;
				width: 100%;
				
				&:focus-visible {
					outline: none;
					border-bottom: 1px solid $white;
					color: $white;
				}
				&:focus {
					outline: none;
				}
			}
			.btn-sec {
				display:flex;
				margin-top: 40px;
				button {
					width: auto;
					padding: 0 25px;
					margin-right:15px;
					cursor:pointer;
				}
			}
		}
	}
}